import React from "react";
import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthContext from "../Usecontexts/Maincontext";
import Box from '@mui/material/Box';
import deskimage from '../../assets/images/Blog/deskimage.png';
import woman_comp from '../../assets/images/Blog/woman_computer.jpeg';


const Card = ({image,date,title,description,topic1,topic2,topic3}) => {

    return(
        <Grid container xs={4} style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            padding:"0px 20px"

        }}>
                 <Grid item xs={12} style={{
                display: "flex",
                flexDirection: "column",
            }}>
                <img alt="woman_on_comp" src={image} style={{
                    flex: "1 0 0",
                    alignSelf: "stretch",
                    height: "270px",
                    objectFit: "cover",
                }}/>
            </Grid>
                <Grid container xs={12} style={{
        display: "flex",
        flexDirection: "row",
        gap: "5px",
        //gap for the rows
    }}>


<Grid container xs={12} style={{
display: "flex",
flexDirection: "row",
gap: "20px",
alignItems: "center",
justifyContent: "flex-start",


}}>
<span style={{
color: "#6941C6",
fontFamily: "Assistant",
fontStyle: "normal",
fontSize: "14px",
lineHeight: "24px",
fontWeight: "600",
letterSpacing: "0.03em",
}}>

{date}
</span>
</Grid>
<Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
alignItems: "flex-start",
justifyContent: "flex-start",
marginTop:"-10px"
}}>
<span style={{
color: "#1A1A1A",
fontFamily: "Assistant",
fontStyle: "normal",
fontSize: "18PX",
lineHeight: "32px",
fontWeight: "600",
}}>

{title}
</span>
</Grid>
<Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
alignItems: "flex-start",
justifyContent: "flex-start",
}}>
<span style={{
color: "#667085",
fontFamily: "Assistant",
fontStyle: "normal",
fontSize: "16px",
lineHeight: "24px",
fontWeight: "400",
}}>
{description}
</span>
</Grid>
<Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
gap: "20px",
alignItems: "flex-start",
justifyContent: "flex-start",
marginTop: "20px",
}}>
<Box style={{
display: "flex",
padding: "2px 10px",
justifyContent: "center",
alignItems: "center",
borderRadius: "16px",
background: "#FDF2FA",
}}>
<span style={{
color: "#C11574",
fontFamily: "Assistant",
textAlign: "center",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "20px",
fontSize: "14px",
}}>
{topic1}
</span>
</Box>
<Box style={{
display: "flex",
padding: "2px 10px",
justifyContent: "center",
alignItems: "center",
borderRadius: "16px",
background: "#F0F9FF",
}}>
<span style={{
color: "#026AA2",
fontFamily: "Assistant",
textAlign: "center",
fontStyle: "normal",
fontWeight: "500",
lineHeight: "20px",
fontSize: "14px",
}}>
{topic2}
</span>
</Box>
</Grid>
         
        
    </Grid>
            </Grid>
    )
}

export default Card;