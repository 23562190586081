import React from "react";
import Grid from '@mui/material/Grid';
import { useState,useContext,useEffect } from 'react';
import axios from 'axios';
import useMediaQuery from '@mui/material/useMediaQuery';
import Calander from "./Calander";
import AuthContext from './Usecontexts/Maincontext';
import Fieldcomp from "./fieldcomp";
import { Checkbox,FormControl,FormControlLabel,Typography } from '@mui/material';
import { gapi } from "gapi-script";
import Place from "./Place";
import hodayaimg from '../assets/images/hodayaimg.png';
import { Box } from "@mui/system";
import CircularProgress from '@mui/material/CircularProgress';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material/styles';
import website1 from '../assets/images/website1.png';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import { Button } from "@mui/material";
import image1_coffee from '../assets/images/image1_coffee.png'
import image2_coffee from '../assets/images/image2_coffee.png'
import image3_coffee from '../assets/images/image3_coffee.png'
import image4_coffee from '../assets/images/image4_coffee.png'
import image1_rest from '../assets/images/image1_rest.png'
import image2_rest from '../assets/images/image2_rest.png'
import image3_rest from '../assets/images/image3_rest.png'
import image4_rest from '../assets/images/image4_rest.png'




const Contact = ({events,setEvents}) => {

    const {t} = useTranslation();
    const [message, setMessage] = useState('')
    const {currentlang}=useContext(AuthContext);
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [businessname, setBusinessname] = useState('')
    const {setIssucceeded}= useContext(AuthContext);
    const {setPopup}= useContext(AuthContext);
    const {checked, setChecked} = useContext(AuthContext);
    const underlg = useMediaQuery('(max-width:1280px)');
    const [calenderpickeddate, setCalenderpickeddate] = useState('')
    const [relevanthours, setRelevanthours] = useState([])
    const [starthour, setStarthour] = useState('')
    const [endhour, setEndhour] = useState('')
    const {isvalidationerror,setIsvalidationerror}= useContext(AuthContext);
    const [eventdate, setEventdate] = useState('')
    const [places, setPlaces] = useState([])
    const [phonenumber, setPhonenumber] = useState('')
    const [timeschecked, setTimeschecked] = useState(0);
    const [userlocation, setUserlocation] = useState({latitude:32.2755416, longitude:34.9140446});
    const DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"];
    const SCOPES = "https://www.googleapis.com/auth/calendar.events";
    const [chosenplace,setChosenplace]=useState({})
    const theme= useTheme();
    const upthenmd= useMediaQuery(theme.breakpoints.up('md'));

    const imagescoffee=[image1_coffee,image2_coffee,image3_coffee,image4_coffee]
    const imagesrest=[image1_rest,image2_rest,image3_rest,image4_rest]




    useEffect(() => {

      const handlesuccess=(position)=>{
        setUserlocation({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        });
        //go to get places
      }

      const handleerror=(error)=>{
        console.error("Error getting user location:", error);
      }


      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(handlesuccess, handleerror);
      } else {
        console.error("Geolocation is not supported by this browser.");
      }

    
    getevents();
    loadgapiclient();
    }, [])

    useEffect(() => {
      getplaces();
    }, [userlocation])




   

    useEffect(() => {

      const hours=[];


      for(let i=0; i<events.length; i++){


        if(events[i].date===calenderpickeddate){
        
          hours.push(events[i]);
        }
      }


      setRelevanthours(hours)       

    }, [calenderpickeddate])



    async function getplaces(){
        
        const response = await axios.get('/getplaces', {
          params: {
            latitude: userlocation.latitude,
            longitude: userlocation.longitude,
            radius: 7,
          },
        });

        let places=response.data;
        let index=0

        //add field of index to each place
        places.map((place)=>{
            place.index=index
            index++
            if(place.types.includes('cafe')){
              place.photos=imagescoffee;
            }
            else{
              place.photos=imagesrest;
            }

            return place;
    
        }
        )

        console.log('this is places:',places);
        setPlaces(places);

    }
    
    function setevent(myevent){

      setStarthour(myevent.starthour);
      setEndhour(myevent.endhour);
      setEventdate(myevent.date);

 


    }

    const getevents = async () => {
      const response = await axios.get('/getevents');
      setEvents(response.data);
    }



    async function loadgapiclient()  {

      const details=await axios.get('/clientcli');
      const clientidcli=details.data.clientcli;
      const apikeycli=details.data.apikey;
       
  

    const initClient = () => {
      gapi.client
        .init({
            clientId: clientidcli ,

          apiKey: apikeycli ,
          discoveryDocs: [
            "https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest",
          ],
          scope: SCOPES,
        })
        .then(() => {
          // Now we can proceed with the auth2 initialization
          gapi.auth2.init({
            client_id: clientidcli,
            scope: SCOPES,
          });
        })
        .catch((error) => {
          console.error("Error initializing Google API client:", error);
        });
    };

    gapi.load("client:auth2", initClient);
  };


  const create_event_client = async (starthouriso,endhouriso) => {

    let location=chosenplace.formatted_address;

    
    

      const authInstance = gapi.auth2.getAuthInstance();
      if (authInstance) {
        authInstance.signIn().then(() => {
          // User signed in, proceed with your logic
          console.log("User signed in");
          // Add the event to the calendar

          const event = {
            summary: 'meeting with DR Development',
            location: location,
            
            start: {
              dateTime: starthouriso,
              timeZone: "Asia/Jerusalem",
            },
            end: {
              dateTime: endhouriso,
              timeZone: "Asia/Jerusalem",
            },
          };

          //wrute the code to add the event to the calendar\
          gapi.client.calendar.events.insert({
            calendarId: "primary",
            resource: event,
          }).then(() => {
            console.log("Event added to the calendar:")
            setIssucceeded(true);
            setPopup(true);
          }).catch((error) => {
            console.error("Failed to add event to the calendar");
            
            });
          }).catch((error) => {
            setIssucceeded(true);
            setPopup(true);
          });



        
      } else {
        console.error("Failed to initialize auth instance.");
      }

  
    
  };

    const handlemessagechange = (e) => {
        setMessage(e.target.value)
    }

    const handlebusinessnamechange = (e) => {
        setBusinessname(e.target.value)
    }

    const handlephonenumberchange = (e) => {
        setPhonenumber(e.target.value)
    }

    const handlenamechange = (e) => {

      setName(e.target.value)


      
    }

    const handleemailchange = (e) => {

      setEmail(e.target.value)
    }

      function handlechecked(e) {
        
        

        if(timeschecked===0){
          var d = new Date();
          var day = d.getDate();
          var month = d.getMonth() + 1;
          var year = d.getFullYear();
      
          if(month<10){
            month='0'+month;
          }
          if(day<10){
            day='0'+day;
          }
          
          const fulldate= year + '-' + month + '-' + day;
          setCalenderpickeddate(fulldate);  


        }
        setTimeschecked(timeschecked+1);
        setChecked(e.target.checked);

        
      
  
        


    }




    const sendmessage= async () => {
        let myname = name;
        let myemail = email;
        let mymessage = message;
        let mybusinessname = businessname;
        let data={}

        const emailformat = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        //write phone number format
        const phoneformat = /^[0-9]{10}$/;
        
        if(mybusinessname===''){
          mybusinessname='No business name';
        }

        if(myname===''){
          myname='No name';
        }

        if(!email.match(emailformat)){
          
          setIssucceeded(false);
          setIsvalidationerror(true);
          setPopup(true);
          return;
        }

    
        if(!phonenumber.match(phoneformat)){

          setIssucceeded(false);
          setIsvalidationerror(true);
          setPopup(true);
          return;
        }


        if(checked){

          if(starthour==='' || endhour==='' || eventdate===''){
            setIssucceeded(false);
            setIsvalidationerror(true);
            setPopup(true);
            return;
          }

          const isostringstartdate= eventdate+'T'+ starthour+':00'+'+03:00';
          const isostringenddate= eventdate+'T'+ endhour+':00'+'+03:00';

          let location=chosenplace.formatted_address;

       

         
          data = {
            name: myname,
            email: myemail,
            message: mymessage,
            businessname: mybusinessname,
            starttime: isostringstartdate,
            endtime: isostringenddate,
            summary:`meeting with ${myname}`,
            location: location
        }

      const response= await axios.post('/create_event', data);

      if(response.status===200){
        console.log('event created successfully')
        //delete from relevnat hours the event that was created specifically with the same start and end hours and date

        

        const newhours = relevanthours.filter((event) => event.starthour!==starthour && event.endhour!==endhour);
        setRelevanthours(newhours);
        const newevents = events.filter((event) => 
          !(event.starthour === starthour && 
            event.endhour === endhour && 
            event.date === eventdate)
        );
        setEvents(newevents);
        create_event_client(isostringstartdate,isostringenddate);

      }
      else{
        setIssucceeded(false);
        setPopup(true);
      }

   
          


         
        }
        else{

         data = {
            name: myname,
            email: myemail,
            message: mymessage,
            businessname: mybusinessname
        }


        const res = await axios.post('/sendmessage', data);
        if(res.status === 200){

          setIssucceeded(true);
          setPopup(true);
        

          
        }
        else{
          setIssucceeded(false);
          setPopup(true);
        
        }
      }


    }


    


    return (

       <>
      

      <Grid id="contact" container style={{
        display:'flex',
        paddingTop:'120px',
        paddingBottom:'120px',
        paddingLeft: underlg?'20px': '83px',
        paddingRight:underlg?'20px': '83px',
        flexDirection:'row',
        justifyContent:'flex-start',
        alignItems:'flex-start',

        gap:'30px'}}>

          <Grid container xs={12} style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            gap:'19px',
            height:"30%"
        }}>

          <Grid xs={12} style={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'21px',
        }}>

            <div style={{
              fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
              color:'var(--Heading-color, #282938)',
              fontSize:'18px',
              fontWeight:600,
              fontStyle:'normal',
              lineHeight:"100%",
          }}>

            {t('letstalk')}

          </div>

          </Grid>

          <Grid xs={12} style={{
            display:'flex',
            flexDirection:'column',
            alignItems:'center',
            gap:'10px',
        }}>
          <div style={{
            fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
            color:'var(--Heading-color, #282938)',
            fontSize: upthenmd? '48px': '32px',
            fontWeight:700,
            fontStyle:'normal',
            lineHeight:"150%",
        }}
        
        >
          {t('connect')}
           </div>

        </Grid>

        <Grid xs={12} style={{
          display:'flex',
          flexDirection:'column',
          alignItems:'center',
          gap:'30px',
      }}>

        <div style={{
          fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
          color:'var(--Heading-color, #282938)',
          fontSize: upthenmd?'24px': '18px',
          fontWeight:400,
          fontStyle:'normal',
          lineHeight:"150%",
          textAlign:'center',
      }}
      
      >      
      {t('dream_comes_true')}
      </div>

      </Grid>

          </Grid>

          <Grid container style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-start',
            height:"70%",
            justifyContent:'center',
            
            // center the form




        }}>

          <Grid container  style={{
            display:'flex',
            flexDirection:'column',
            alignItems:'flex-start',
            justifyContent:'flex-start',
            
            // make
            
        }}>

          <Grid container  style={{
            flexDirection:'row',
            alignItems:'flex-start',
            display:'flex',
            justifyContent:'center',
            gap:'10px',
            
            marginBottom:'20px'

          }}>

        
          

            <Fieldcomp fieldname= {t('your_name')} handlechange={handlenamechange} />
            
          
            <Fieldcomp fieldname={t('business_name')} handlechange={handlebusinessnamechange}/>





            </Grid>

            <Grid container style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-start',
            gap:'10px',

            justifyContent:'center',
            marginBottom:'30px'
          }}>

            <Fieldcomp fieldname={t('email')} handlechange={handleemailchange} />

            <Fieldcomp fieldname={t('phone')} handlechange={handlephonenumberchange} />


            </Grid>

           
            <Grid container style={{
            display:'flex',
            flexDirection: currentlang==='he'?'row':'row-reverse',
            alignItems:'flex-start',
            gap:'10px',

            justifyContent:'center',
            marginBottom:'30px',
          
          }}>

<Grid item xs={12} xl={6} style={{
              display:'flex',
              flexDirection:'column',
              alignItems: currentlang==='he'? 'flex-start':'flex-end',
              justifyContent:'flex-start',
              gap:'20px',
              width: '100%',

              
              

          }}>

        <Grid xs={12} style={{
            display:'flex',
            flexDirection:  'row',
            alignItems:'center',
            justifyContent: 'flex-start',
            //reverse the order of the elements in the row
            
            

        }}>

<FormControlLabel control={

<Checkbox  style={{
  color:'var(--Primary, #5E3BEE)',
  padding:'0px',
  margin:'0px',
  alignSelf:'center',
  marginTop:'2px'
  
  
  
  
                 }}
                 checked={checked}
                 onChange={handlechecked}
                 />  }
               label={
                <Typography style={{
                  fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
                  color:'var(--Heading-color, #282938)',
                  fontSize:'18px',
                  fontWeight:400,
                  fontStyle:'normal',
                  padding:'5px'
               }}> 
               
               {t('schedule')}
               </Typography>}
               labelPlacement="start"
           
              


                              />


          </Grid>

          
          {checked && (
  <Grid
    xs={12}
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: "30px",
      width: "100%",
      // Animation to fade in and out, plus slight movement
      animation: "fadeInOut 6s ease-in-out infinite",
    }}
  >
    <div
      style={{
        fontFamily: "Assistant",
        color: "var(--Primary, #5E3BEE)",
        fontSize: "24px",
        fontWeight: 400,
        fontStyle: "normal",
        lineHeight: "150%",
        textAlign: "center",
        // We apply the opacity animation with fade-in and fade-out
        opacity: 0,
        transform: "translateY(10px)", // Start position for smooth movement
        animation: "fadeInOutText 6s ease-in-out infinite",
      }}
    >
      {t('meeting_message')}
    </div>
  </Grid>
)}

          <Grid item xs={12} style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent:'center',
            alignSelf:'center', 
            marginTop:'20px',
            marginBottom:'20px',
            //let me scroll to the sides

            

        }}>
          {  checked && places.length>0?
          <Place name="DR Development" description="כתובת: רחוב יפו 9, תל אביב" image={hodayaimg} places={places}  ischecked={checked}
          
          chosenplace={chosenplace} setChosenplace={setChosenplace}
          /> : checked && places.length===0? 
          <CircularProgress />
      
                            : null
          
          
         
}

          </Grid>



          </Grid>

          <Grid item xs={12} lg={3.5} style={{
              display:'flex',
              flexDirection:'column',
              alignItems:'center',
              justifyContent:'flex-start',
              gap:'20px',
              //make the contects closer
          }}>

        <Grid xs={12} style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent: 'center',

        }}>

        <Calander ischecked={checked} calenderpickeddate={calenderpickeddate} setCalenderpickeddate={setCalenderpickeddate}/>


          </Grid>

          <Grid container xs={12} style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'center',
            justifyContent: 'center',
            gap:'10px',

        }}>

          {
            relevanthours.map((event) => {

                //need to set event that will be the chosen one in this moment and then check it in the map function
              return (
              <Grid item xs={8} sm={4} style={{
                display:'inline-flex',
                padding:"18px 32px",
                borderRadius:"30px",
                justifyContent:'center',
                alignItems:'center',
                backgroundColor:(event.starthour==starthour && event.date=== eventdate && event.endhour==endhour) ? '#22876b':'var(--Primary, #5E3BEE)', 
                boxShadow: "0px 4px 15px 0px rgba(46, 46, 46, 0.15);",
                cursor:'pointer',
               }} onClick={()=>setevent(event)} >
                  <span style={{
                  fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
                  color:'var(--White, #FFF)',
                  fontSize:'14px',
                  fontWeight:400,
                  fontStyle:'normal',
                  lineHeight:'150%',
                  width:'100%',
                  textAlign:'center',
                  
                }}>{event.starthour}-{event.endhour}</span>
                
               </Grid>
               
                
               
              )
               
          } )
        }

          
           
             
          </Grid>
          


          </Grid>


          

            </Grid>

            <Grid container xs={12} style={{
            display:'flex',
            flexDirection:'row',
            alignItems:'flex-start',
            marginBottom:'30px',
            
            justifyContent:'center',
          }}>

            <Fieldcomp fieldname= {t('send_message')} iswide={true} handlechange={handlemessagechange} />

          

            </Grid>

            <Grid item xs={12} style={{
        display:'flex',
        flexDirection:'row',
        alignItems:'flex-start',
        gap:'10px',
        justifyContent:'center',
        width:'100%',

      }}>

        <div 
        onClick={sendmessage}

         style={{
          display:'flex',
          borderRadius:'8px',
          backgroundColor:'var(--Primary, #5E3BEE)',
          paddingTop:'18.667px',
          paddingBottom:'18.667px',
          paddingLeft:'40px',
          paddingRight:'40px',
          gap:'10px',
          justifyContent:'center',
          cursor:'pointer',

          

        }}>

<span style={{
                fontSize:'21.33px',
                fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
                fontWeight:400,
                lineHeight:'150%',
                color:'var(--White, #FFF)',
                fontStyle:'normal',
              }}>
                {checked? t('schedule'):t('send')}
              </span>

                
            </div>        
          

      

        </Grid>
               


           

          </Grid>

          </Grid>


       

          
          

              

              
      



        </Grid>
        </>

       
    )
}

export default Contact


