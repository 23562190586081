import React from 'react'
import { useEffect } from 'react'
import { useTheme } from '@mui/material/styles'
import { useState } from 'react'
import Box from '@mui/material/Box'
import MyRating from './Rating'
import { useTranslation } from 'react-i18next';
import AuthContext from './Usecontexts/Maincontext'




const PlaceSpecific = ({name,types,rating,icon,address,latitude,longitude,chosenplace,setChosenplace,ischecked,placefull}) => {

    const { t } = useTranslation();
    const {currentlang}= React.useContext(AuthContext)
    const md_and_down = useTheme().breakpoints.down('md')

let images=placefull.photos
console.log('images:',images)
const [currentimage,setCurrentimage]=useState(images[0])

let index=0;
const [isfading,setIsfading]=useState(false)

const ishebrew= (str)=>{

    return /[\u0590-\u05FF]/.test(str);


}



useEffect(()=>{
    setInterval(() => {

        setIsfading(true)




        setTimeout(() => {

        if(index>images.length-2){
            index=0;
            setCurrentimage(images[0])
        }
        else{
            setCurrentimage(images[++index])
            
        }
        setIsfading(false)
    }, 1000);

        

        
    },5000);
},[])

useEffect(()=>{
    console.log('chosen place:',chosenplace)
},[chosenplace])

const setchosenplace = (placefull)=>{
    setChosenplace(placefull)
}

    return(

        <Box onClick={()=> setchosenplace(placefull)} id={placefull.index} sx={{ display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height:  '550px',
            width:  '350px',
            paddingLeft:'10px',
            alignItems:'flex-start',
            background: 'var(--BG-Shade, #F5FCFF)',
            margin:'10px',
            marginTop:'0px',
            cursor:'pointer',
            //make it a bit transparent if it is not chosen
            opacity: !ischecked? '0.5':chosenplace.index===placefull.index? '1':'0.5',
             }} >
                   <Box xs={12} style={{
                                display:'flex',
                                gap:'14px',
                                borderRadius:'10px',
                                backgroundColor:"#FFF",
                                marginBottom:'-13px',
                                zIndex:1
                                
                            }}>

            
                                <img src={currentimage} alt="symbol" style={{width:'350px',  height:'250px',objectFit:'cover', borderRadius:'8px',

                                  opacity: chosenplace.index===placefull.index || !isfading? '1':'0',
                                  transition: 'opacity 1s ease-in-out',

                                }}/>
            
            
                            </Box>
                 <Box  id="contact2" style={{
                        display:'flex',
                        paddingBottom:'22px',
                        gap:'10px',
                        borderRadius:"10px",
                        borderBottom:"4px solid #F5FCFF",
                        boxShadow:"0px 0px 32px 0px rgba(0, 0, 0, 0.15)",
                        transition: 'opacity 3s ease-in-out,transform 2s ease-in-out',
                        width:'100%',

                   
            
                    }}>
            
                     
            
                            <Box xs={12} style={{ flexDirection:'column',paddingTop:"30px", justifyContent:'flex-start' ,alignItems:'flex-start', alignSelf:'stretch', gap:'12px',padding:"20px", height:"100%",display:'flex'}} >
            
                                <div style={{fontFamily:ishebrew(name)? 'Assistant':'Roboto', color:'#282938', fontSize: md_and_down?'20px': '28px', fontWeight:ishebrew(name)? 600:400, fontStyle:'normal',lineHeight:"100%"}}>
            
                                {name.length>30? name.slice(0,30)+'...': name}
                                </div>

                                <MyRating name="half-rating-read" myvalue={rating}/>

                                <div style={{fontFamily: ishebrew(address)? 'Assistant':'Roboto', color:'#282938', fontSize: md_and_down? '14px': '18px', fontWeight:400, fontStyle:'normal',}}>
            
                                  {address}


                                   </div>
            
                                <div style={{fontFamily:'Roboto', color:'#282938', fontSize: md_and_down? '14px': '18px', fontWeight:400, fontStyle:'normal',}}>
            
                                    
                                {
                                    //take only the first 3 types
                                    types.slice(0,3).map((type,index)=>{

                                        if(type=='point_of_interest'){
                                            return <span key={index}> meeting place </span>
                                        }
                                        if(index===2){
                                            return <span key={index}>{type}</span>

                                        }
                                        else{
                                            return <span key={index}>{type}, </span>
                                    }

                                    })
                                    
                                }
            
                                </div>
                                
                                <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', color:'#282938', fontSize:'18px', fontWeight:400, fontStyle:'normal',}}>
            
                                    
                                <img src={icon} alt="symbol" style={{width:'20px',height:'20px',objectFit:'cover', padding:'5px',marginTop:'1px'}}/>
                                   
            
                                </div>
                                <div style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', cursor:'pointer', color:'#282938', fontSize:'18px', fontWeight:400, fontStyle:'normal', height:"20px", display:'flex',alignItems:'center'}} onClick={()=> {
                                    window.open(`https://waze.com/ul?ll=${latitude},${longitude}&navigate=yes`)
                                }}>

                                    <span style={{ marginRight: currentlang==='he'? '0px': '5px' ,fontSize: md_and_down? '14px': '18px', marginLeft: currentlang==='he'? '5px': '0px'}}>


                                    {t('navigate_with')}
                                    </span>
            
                                <svg xmlns="http://www.w3.org/2000/svg" width="1em" height="1em" viewBox="0 0 24 24">
	<path fill="#4285F4"  d="M20.54 6.63c.69.94 1.15 2.04 1.35 3.19c.21 1.25.11 2.52-.31 3.72a7.35 7.35 0 0 1-2 3.06a9.1 9.1 0 0 1-2.26 1.58c.41 1.07-.13 2.27-1.2 2.68c-.24.09-.49.14-.74.14a2.08 2.08 0 0 1-2.07-2h-3.07c-.11 1.14-1.13 2-2.27 1.87c-1.06-.1-1.86-.98-1.88-2.04c.01-.19.04-.39.1-.57a8.37 8.37 0 0 1-4-2.85c-.33-.44-.23-1.07.23-1.41c.18-.14.4-.22.63-.22c.72 0 1-.25 1.17-.63c.24-.72.38-1.47.39-2.23c.03-.53.09-1.05.17-1.57A7.3 7.3 0 0 1 7.5 5c1.66-1.3 3.69-2 5.79-2c1.43 0 2.84.35 4.11 1a8.7 8.7 0 0 1 3.14 2.63m-3.82 10.68c1.78-.81 3.18-2.27 3.87-4.1c1.62-4.94-2.59-9.16-7.3-9.16c-.35 0-.71.02-1.06.07C9.36 4.5 6.4 6.5 5.81 9.5c-.38 2 .19 5.29-2.76 5.29C4 16 5.32 16.93 6.81 17.37c.85-.76 2.16-.68 2.93.18c.11.12.2.25.26.39h3.55c.52-1.02 1.78-1.44 2.8-.9c.15.08.25.17.37.27m-5.75-7c-.58.03-1.09-.41-1.12-1c-.03-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06a1 1 0 0 1-.97 1.04zm4.69 0c-.58.03-1.09-.41-1.12-1c-.04-.58.42-1.08 1-1.12c.58-.03 1.09.42 1.12 1.06c.02.55-.41 1.02-1 1.04zm-5.95 1.76c-.06-.28.13-.57.41-.62s.56.13.62.41a2.5 2.5 0 0 0 2.58 1.74c1.14.06 2.18-.64 2.57-1.72c.14-.26.46-.38.71-.23c.18.1.29.27.29.47c-.19.71-.63 1.33-1.23 1.76c-.69.48-1.5.75-2.34.76h-.11c-1.63.07-3.1-1-3.53-2.58z" />
</svg>

               

                                  </div>
        
                            

            
                      
                                
            
                              
                            </Box>
                            
            
            
                        </Box>
                        </Box>
    )
}

export default PlaceSpecific