// write a react component called Header that renders the header element

import React from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Link } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useState,useContext,useEffect } from 'react';
import {AiFillGithub} from 'react-icons/ai';
import {BsFacebook} from 'react-icons/bs';
import {FaLinkedin} from 'react-icons/fa';
import MenuItem from '@mui/material/MenuItem';
import Menu from '@mui/material/Menu';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import Drawer from '@mui/material/Drawer';
import { Button } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import drimage from '../assets/images/DR.png'
import mylogo from '../assets/images/my_logo.png'
import { useTheme } from '@mui/material/styles';
//bring router
import { BrowserRouter as Router } from 'react-router-dom';
import { Routes,Route } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ListItemButton from '@mui/material/ListItemButton';
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import AuthContext from './Usecontexts/Maincontext';
import ReactCountryFlag from "react-country-flag";
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';









const Header = () => {



    const {currentlang,setCurrentlang}= useContext(AuthContext);
    const languages = ['עברית', 'English'];
    const theme= useTheme();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const {value, setValue} = useContext(AuthContext);
    const {ishomepage, setIshomepage} = useContext(AuthContext);
    const {menupress,setMenupress}= useContext(AuthContext)
    const ismobile = useMediaQuery('(max-width:600px)');
    const [drawerOpen, setDrawerOpen] = useState(false);   
    const isntmd = useMediaQuery(theme.breakpoints.down('md')); 
    const isntlg = useMediaQuery(theme.breakpoints.down('lg'));

    const handleLanguageChange = (language) => {

        if(language==='English'){
    
            setCurrentlang('en');
            setOpen(false);
            return;
        }
        else{
            setCurrentlang('he');
            setOpen(false);
            return;
        
        }
       
        };

    


    const handleclick= (value) => {

        //להוסיף 'none' ברגע שנוסיף את הבלוג ל section
       
        const section= ['header','partners','none','package','about','contact'][value];

        if(section === 'none'){
            return;
        }

        
            const sectionelement = document.getElementById(`${section}`);
            if(sectionelement){
          console.log(sectionelement);
          sectionelement.scrollIntoView({behavior:'smooth',
        
        block:'start'})
            }
            //scroll more down 
            
    
    }
 

        

    const tabStyle = {
        fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
        fontSize: isntmd? '1.1rem': '1rem',
        textTransform: 'none',
        color: 'black', 
        zindex:ismobile? 2: 1,
        fontWeight:isntmd? 600:400
        
      };

      const tabStyleselected = {
        fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
        fontSize: isntmd? '1.1rem':'1rem',
        textTransform: 'none',
        color: "#5E3BEE",
        zindex: 1,
        fontWeight:400
      };

      useEffect(() => {
        if(drawerOpen){
            setMenupress(true);
        }
        else{
            setMenupress(false);
        }
    }, [drawerOpen])

      const tabs= (  <Tabs centered={!ismobile} indicatorColor='none'
      value={value} 

      onChange={(event, newValue) => {


        console.log(newValue);
          setValue(newValue);

          handleclick(newValue);
        

          
         
           
      }}




      style={{

        display:'flex',
        justifyContent:'space-between',
        alignItems:'center',
        width:'100%',
        marginTop:'12px',
        

       
    
    }}

      

      
      >
          <Tab label={t('home_menu')}

          

          style={ value !== 0 ? tabStyle: tabStyleselected }
          component={!ishomepage?Link:null} to="/" value={0}

          

          
          

          />
           <Tab label={t('projects_menu')}

style={ value !== 1 ? tabStyle: tabStyleselected }
component={!ishomepage?Link:null} to="/" value={1}






/>

{/* <Tab label="בלוג"

style={ value !== 2 ? tabStyle: tabStyleselected }
component={ishomepage?Link:null} to="/blog"






/> */}
             <Tab label={t('services_menu')}
          style={ value !== 3 ? tabStyle: tabStyleselected }

          component={!ishomepage?Link:null} to="/" value={3}

         
          
          />
          
     
          <Tab label={t('about_menu')}
          style={ value !== 4 ? tabStyle: tabStyleselected }
          component={!ishomepage?Link:null} to="/" value={4}

          />
       
          <Tab label={t('contact_menu')}
          style={ value !== 5 ? tabStyle: tabStyleselected }
          component={!ishomepage?Link:null} to="/" value={5}


          />



      </Tabs>
      );

    return (
        <>
        <Grid id="header" container  direction='row' 
        justifyContent="space-between"
        style={{ zIndex:2,
        boxShadow: ishomepage? '0px 5.333px 80px 0px rgba(0, 0, 0, 0.10)' : null,
        padding:'0px 30px',
        display:'flex',
        paddingBottom: isntmd? '48px': '22px',
        borderBottom: '1px solid #e0e0e0',
        
        
        //make box 
    
    }}

        >


   
  


            <Grid container item xs="12" md="10"
            
            style={{display:'flex',justifyContent:'space-around',alignItems:'center', 
        
        }}

            >

                <Grid item xs="2" style={{display:'flex',justifyContent:'center'}}>
                <img src={mylogo} alt='logo' style={{width:isntmd? "100px":"140px",position:'absolute', 
                
            
            marginTop:isntmd? "-25px":'-61px',
            marginRight:currentlang==='he'? '0px': '35px',
            marginLeft:currentlang==='en'? '0px': '35px',
          

            }}/>
                </Grid>
            
 

                <Grid container item xs="10" style={{display:'flex',justifyContent:'flex-end',


                }}>

                   
        


                        

                {
                    isntmd ? (
                        <>
                        
                        <IconButton edge="start" color="inherit" aria-label="menu" onClick={() => setDrawerOpen(!drawerOpen)}>
                            <MenuIcon style={{color:"#000000", width:"100%",
                            position:'absolute', left:'10', top:'20', fontSize:'2rem', zIndex:1,
                            transform: 'scale(1.5)'
                        }}  />
                        </IconButton>
                        <Drawer
                        anchor= {currentlang==='he'? 'left': 'right'}
                        open={drawerOpen}
                        onClose={() => setDrawerOpen(false)}
                        sx={{width:'50%',

                          flexShrink: 0,
                          '& .MuiDrawer-paper': {
                            width: '50%',
                            boxSizing: 'border-box',
                          },
                        }}
                        >
                          
                          <Grid container sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', gap: '60px', padding: '20px' }}>
                          
                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center'}}>
                          <img src={mylogo} alt='logo' style={{width:isntmd? "150px":"200px"
                        
                          }}/>
                          </Grid>

                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',flexDirection:'row'}} onClick={()=> {
                          handleclick(0)
                          setDrawerOpen(false)


                          
                          }


                          }>
                          <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize:'25px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>
                          {t('home_menu')}
                          </span>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',flexDirection:'row'}} onClick={()=>{
                          handleclick(1)
                          setDrawerOpen(false)
                          }}>
                          <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize:'25px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>
                          {t('projects_menu')}
                          </span>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',flexDirection:'row'}} onClick={()=>{
                          handleclick(2)
                          setDrawerOpen(false)
                          }}>
                          <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize:'25px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}} onClick={()=>{
                          handleclick(3)
                          setDrawerOpen(false)
                          }}>
                          {t('services_menu')}
                          </span>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',flexDirection:'row'}} onClick={()=>{
                          handleclick(4)
                          setDrawerOpen(false)
                          }}>
                          <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize:'25px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>
                          {t('about_menu')}
                          </span>
                          </Grid>
                          <Grid item xs={12} style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'20px',flexDirection:'row'}} onClick={()=>{
                          handleclick(5)
                          setDrawerOpen(false)
                          }}>
                          <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize:'25px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>
                            
                          {t('contact_menu')}
                          </span>
                          </Grid>
                         
                          </Grid>


                        </Drawer>
                        
<Grid item xs={6}  style={{display:'flex',
            marginRight: currentlang==='en'? '40px': '0px', 
            marginLeft: currentlang==='he'? '40px': '0px',
            top:20,
            left: currentlang==='he'? 65: '',
            right: currentlang==='en'? 65: '',

            zIndex:100,
            position:'absolute',
            flexDirection:'column',
            width:'7%',
            
            
            
          
            }}>
     
     <ListItemButton
       onClick={() => setOpen(!open)}
       sx={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         padding: 0,
         zIndex:1,
         //expand the clickable area
         
       }}
     >
                 <span style={{fontFamily:'Assistant',marginTop:"4px" , fontSize:'15px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>

                     {t('language')}
                 </span>

                 {
                     !open && currentlang==='en'? 

         <KeyboardArrowRight
           sx={{
             color: 'var(--Primary, #5E3BEE)',
             fontSize: 20,
             mt: 0.625,
             marginRight: currentlang==='en'? '0px': '5px',
             marginLeft: currentlang==='he'? '0px': '5px',
             zIndex:1,
           }}
         /> : !open && currentlang==='he'? <KeyboardArrowLeft
         sx={{
             color: 'var(--Primary, #5E3BEE)',
             fontSize: 20,
             mt: 0.625,
             marginRight: currentlang==='en'? '0px': '5px',
             marginLeft: currentlang==='he'? '0px': '5px',
              zIndex:1,
           }} /> : open ? <KeyboardArrowDown
         sx={{
           color: 'var(--Primary, #5E3BEE)',
           fontSize: 20,
           mt: 0.625,
           marginRight: currentlang==='en'? '0px': '5px',
           marginLeft: currentlang==='he'? '0px': '5px',
           zIndex:1,
         }}
         /> : ''
}
       
      
     </ListItemButton>
     <Collapse in={open} timeout="auto" unmountOnExit>
     <List component="div" disablePadding>
       {languages.map((language, index) => (
         <ListItem
           key={index}
           button
           onClick={() => handleLanguageChange(language)}
           sx={{
             fontFamily: 'Assistant',
             fontSize: 15,
             justifyContent: 'center',
             fontWeight: 400,
             color: 'var(--Primary, #5E3BEE)',
             lineHeight: '150%',
             padding: '12.667px 20.667px',
             cursor: 'pointer',
             zIndex:1,
           }}
         >
           {language}
           {language==='English'? 
         <ReactCountryFlag 
         countryCode="US"
         svg
         style={{
             width: '1.5em',
             height: '1.5em',
             marginLeft:'5px',
             marginRight:'5px'
         }}
         arie-label='United States'
         /> : <ReactCountryFlag
         countryCode="IL"
         svg
         style={{
             width: '1.5em',
             height: '1.5em',
             marginLeft:'5px',
             marginRight:'5px'
         }}

         arie-label='Israel'
         />
         }
         </ListItem>
       ))}
     </List>
   </Collapse>
</Grid>
         

        
     
                        
                    

                        </>
                    ) : (
                        tabs
                    )

                }

                

</Grid>

       
        </Grid>

        

      

  
        <Grid container item sm="0" md="2"
        
        style={{flexDirection:'row',display:isntmd? 'none' :'flex',justifyContent:'flex-end',
    alignSelf:'center', marginTop:'12px', 
    
    }}>

<Grid item xs={6}  style={{display:'flex',
     
            top:20,
            left: isntmd? 40: isntlg? 170: 220,

            zIndex:1,
            position:'absolute',
            flexDirection:'column',
            width:'7%',
            
            
            
          
            }}>
     
     <ListItemButton
       onClick={() => setOpen(!open)}
       sx={{
         display: 'flex',
         justifyContent: 'center',
         alignItems: 'center',
         padding: 0,
         //expand the clickable area
         
       }}
     >
                 <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto',marginTop:"4px" , fontSize:'15px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'100%'}}>

                     {t('language')}
                 </span>

                 {
                     !open && currentlang==='en'? 

         <KeyboardArrowRight
           sx={{
             color: 'var(--Primary, #5E3BEE)',
             fontSize: 20,
             mt: 0.625,
             marginRight: currentlang==='en'? '0px': '5px',
             marginLeft: currentlang==='he'? '0px': '5px',
           }}
         /> : !open && currentlang==='he'? <KeyboardArrowLeft
         sx={{
             color: 'var(--Primary, #5E3BEE)',
             fontSize: 20,
             mt: 0.625,
             marginRight: currentlang==='en'? '0px': '5px',
             marginLeft: currentlang==='he'? '0px': '5px',
           }} /> : open ? <KeyboardArrowDown
         sx={{
           color: 'var(--Primary, #5E3BEE)',
           fontSize: 20,
           mt: 0.625,
           marginRight: currentlang==='en'? '0px': '5px',
           marginLeft: currentlang==='he'? '0px': '5px',
         }}
         /> : ''
}
       
      
     </ListItemButton>
     <Collapse in={open} timeout="auto" unmountOnExit>
     <List component="div" disablePadding>
       {languages.map((language, index) => (
         <ListItem
           key={index}
           button
           onClick={() => handleLanguageChange(language)}
           sx={{
             fontFamily: currentlang==='he'? 'Assistant': 'Roboto',
             fontSize: 15,
             justifyContent: 'center',
             fontWeight: 400,
             color: 'var(--Primary, #5E3BEE)',
             lineHeight: '150%',
             padding: '12.667px 20.667px',
             cursor: 'pointer',
           }}
         >
           {language}
           {language==='English'? 
         <ReactCountryFlag 
         countryCode="US"
         svg
         style={{
             width: '1.5em',
             height: '1.5em',
             marginLeft:'5px',
             marginRight:'5px'
         }}
         arie-label='United States'
         /> : <ReactCountryFlag
         countryCode="IL"
         svg
         style={{
             width: '1.5em',
             height: '1.5em',
             marginLeft:'5px',
             marginRight:'5px'
         }}

         arie-label='Israel'
         />
         }
         </ListItem>
       ))}
     </List>
   </Collapse>
</Grid>
       

            <Grid onClick={()=>{
                handleclick(4);
            }}  lg="8" xl="6" style={{display:'flex',justifyContent:'center', borderRadius:'8px', border:'1.333px solid var(--Primary, #5E3BEE)',
        fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize: isntlg? '11px': '15px', fontWeight:400, color:'var(--Primary, #5E3BEE)', lineHeight:'150%',padding:'12.667px 12.667px', cursor:'pointer',maxHeight:'50px'
        }}>

                {t('contact_us')}

                </Grid>

                

      

     
   

  
     </Grid>
         
    
     
    
        </Grid>

       
   
        </>
    )
}

export default Header;

