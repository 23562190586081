import React from "react";
import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthContext from "../Usecontexts/Maincontext";
import Box from '@mui/material/Box';
import deskimage from '../../assets/images/Blog/deskimage.png';
import woman_comp from '../../assets/images/Blog/woman_computer.jpeg';
import Right_Top_Content from "./Right_Top_Content";
import bottom_left_img from '../../assets/images/Blog/bottom_left_img.png';
import Divider from '@mui/material/Divider';
import Card from "./Card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Blogsubpost = () => { 

    return(
        <Grid container style={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",

        }}>


     <Grid container xs={12} style={{
                    display: "flex",
                    flexDirection: "row",
                }}>
                    <img alt="deskimage" src={deskimage} style={{
                        flex: "1 0 0",
                        alignSelf: "stretch",
                        height: "220px",
                        width: "100%",
                        
                        objectFit: "cover",
                        boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                    }} />
                
                </Grid>

                <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                    }}>

                   
                   <span style={{
                            color: "#6941C6",
                            fontFamily: "Assistant",
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.03em",
                        }}>
                            ראשון, 4 לאוגוסט 2024
                        </span> 

                        </Grid> 
                        <Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
alignItems: "flex-start",
justifyContent: "flex-start",
marginTop:"-10px"
}}>
<span style={{
    color: "#1A1A1A",
    fontFamily: "Assistant",
    fontStyle: "normal",
    fontSize: "18PX",
    lineHeight: "32px",
    fontWeight: "600",
}}>
            אילו תובנות ניתן להפיק באמצעות AI?

</span>
</Grid>
<Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
alignItems: "flex-start",
justifyContent: "flex-start",
}}>
<span style={{
    color: "#667085",
    fontFamily: "Assistant",
    fontStyle: "normal",
    fontSize: "16px",
    lineHeight: "24px",
    fontWeight: "400",
}}>
    באמצעות AI ניתן לבצע המון דברים כגון
</span>
</Grid>
<Grid item xs={12} style={{
display: "flex",
flexDirection: "row",
gap: "20px",
alignItems: "flex-start",
justifyContent: "flex-start",
marginTop: "20px",
}}>
<Box style={{
    display: "flex",
    padding: "2px 10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    background: "#FDF2FA",
}}>
    <span style={{
        color: "#C11574",
        fontFamily: "Assistant",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        fontSize: "14px",
    }}>
        מחקר
    </span>
</Box>
<Box style={{
    display: "flex",
    padding: "2px 10px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "16px",
    background: "#F0F9FF",
}}>
    <span style={{
        color: "#026AA2",
        fontFamily: "Assistant",
        textAlign: "center",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "20px",
        fontSize: "14px",
    }}>
        טכנולוגיות
    </span>
</Box>
</Grid>

    </Grid>

    )
}

export default Blogsubpost;








