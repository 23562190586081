//create component

import React from 'react';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import { useState,useContext,useEffect } from 'react';
import Button from '@mui/material/Button';
import { useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import i18n from '../i18n';
import AuthContext from './Usecontexts/Maincontext';


//import Text




const Lefthome = () => {

    const {currentlang}=useContext(AuthContext);
    const theme = useTheme();
    const ismobile = useMediaQuery('(max-width:600px)');
    const { t } = useTranslation();
    const isRtl = i18n.dir==='rtl';




    const ismd = useMediaQuery(theme.breakpoints.up('md'));

    // const handleclick= (value) => {
    //     const section= ['home','partners','package','about','contact'][value];
    //         const sectionelement = document.getElementById(`${section}`);
    //         if(sectionelement){
    //       console.log(sectionelement);
    //       sectionelement.scrollIntoView({behavior:'smooth',
        
    //     block:'start'})
    //         }
    // }


    return (
        <Grid container item xs={12} lg={6} style={{

            display: 'flex',
            flexDirection: 'column',
            alignItems:'flex-start',
            paddingTop: !ismd? '30px':'5px',
            flexShrink: 0,
            paddingInlineStart:ismd?'100px' : '20px'

        }}>

            <Grid item xs={12} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'flex-start',
                alignSelf:'stretch',
                gap:'32px',
            }}>

                <div
                style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto',fontSize:'21.333px',fontWeight:600,marginBottom:'10px', color:'#282938',lineHeight:"150%", fontStyle:'normal',letterSpacing:'0.15px'}}
                
                >

                   {t('titlemessage')}
                    
                    
                </div>
                <div style={{alignSelf:'stretch'}} >

                    <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize: ismd && currentlang==='he'?'55.667px' : !ismd&&currentlang==='he'? '40px': ismd&& currentlang==='en'? '40px' : '32px', fontStyle:'normal', fontWeight:700,lineHeight:'120%'}}>

                        {t('title2')}


                    </span>
                    <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize: ismd && currentlang==='he'?'55.667px' : !ismd&&currentlang==='he'? '40px': ismd&& currentlang==='en'? '40px' : '32px', fontStyle:'normal', fontWeight:700,lineHeight:'120%',color:'#5E3BEE',paddingInlineEnd:ismd?'20px':'10px', paddingInlineStart:ismd?'20px':'10px'}}>
                        {t('title3')}

                        </span>
                        <span style={{fontFamily: currentlang==='he'? 'Assistant': 'Roboto', fontSize: ismd && currentlang==='he'?'55.667px' : !ismd&&currentlang==='he'? '40px': ismd&& currentlang==='en'? '40px' : '32px', fontStyle:'normal', fontWeight:700,lineHeight:'120%'}}>

                        {t('title4')}



                    </span>


                </div>
                


            </Grid>
            <Grid item xs={12} style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems:'flex-start',
                alignSelf:'stretch',
                paddingTop:'40px',
                paddingBottom:'40px',
            }}>
              <Button onClick={()=> { 
                            window.open("https://wa.me/972544474788")



              }} variant="contained" style={{backgroundColor:'#5E3BEE',color:'white',padding:'15px 30px',borderRadius:'10px',fontSize: currentlang==='he'? '17.333px':'14px',fontWeight:600,fontFamily: currentlang==='he'? 'Assistant': 'Roboto',lineHeight:'150%',letterSpacing:'0.15px'}}>
                    {t('letsgo')}
                </Button>
                </Grid>

            </Grid>

    );
}

export default Lefthome;