import React from "react";
import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthContext from "../Usecontexts/Maincontext";
import Box from '@mui/material/Box';
import deskimage from '../../assets/images/Blog/deskimage.png';
import woman_comp from '../../assets/images/Blog/woman_computer.jpeg';
import Right_Top_Content from "./Right_Top_Content";
import bottom_left_img from '../../assets/images/Blog/bottom_left_img.png';
import Divider from '@mui/material/Divider';
import Card from "./Card";
import Blogsubpost from "./Blogsubpost";

const DetailedBlog = ({content,mainimage,image1,image2,image3,image4,image5,subcontent}) => {

    return (
    <Grid container style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignSelf: "stretch",
        gap: "64px",
        padding: "30px 0px",
        backgroundColor: "#FFF",

        

    }}
    >

        <Grid container style={{
            display: "flex",
            flexDirection:"row",
            justifyContent: "flex-start",
            gap: "32px",
            width: "1600px",
            
        }}>

            <Grid container style={{
                display: "flex",
                flexDirection:'row',
                alignSelf: "stretch",
                justifyContent: "flex-start",
                gap: "32px",
            }}>

                   <Grid container xs={9} style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    justifyContent: "flex-start",
                    gap: "32px",
                    flex: "1 0 0",
                    alignSelf: "stretch",
                }}>
                      <Grid container style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            

        }}>
                       <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                    }}>

                   
                   <span style={{
                            color: "#6941C6",
                            fontFamily: "Assistant",
                            fontStyle: "normal",
                            fontSize: "14px",
                            lineHeight: "24px",
                            fontWeight: "600",
                            letterSpacing: "0.03em",
                        }}>
                            ראשון, 4 לאוגוסט 2024
                        </span> 

                        </Grid> 

                        <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                    }}>

                   
                   <span style={{
                            color: "#000",
                            fontFamily: "Assistant",
                            fontStyle: "normal",
                            fontSize: "36px",
                            lineHeight: "32px",
                            fontWeight: "700",
                        }}>
                            ראשון, 4 לאוגוסט 2024
                        </span> 

                        </Grid> 

                        <Box style={{

                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            alignItems: "flex-start",
                            justifyContent: "flex-start",
                            marginTop: "20px",
                        }}>
                            <img src={bottom_left_img} alt="deskimage" style={{
                                width: "90%",
                                height: "450px",
                                borderRadius: "10px",
                                
                            }} />

                        </Box>

                        </Grid>
                        <Grid container style={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",

        }}>

                        <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                        color: "#4F4F4F",
                        fontFamily: "Assistant",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        alignSelf: "stretch",
                        
                        
                        
                        }}>
                           
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה asdasdazz בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיsaddק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלassadחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בasddsadsadsadדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקהasdasdsad בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי


                        </Grid>
                        
                        <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                        color: "#4F4F4F",
                        fontFamily: "Assistant",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: "400",
                        lineHeight: "24px",
                        }}>
                           
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי
                         שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי


                        </Grid>

                        <Grid item xs={12} style={{
                        flexDirection: "row",
                        display: "flex",
                        color: "#000",
                        fontFamily: "Assistant",
                        fontStyle: "normal",
                        fontSize: "16px",
                        fontWeight: "700",
                        lineHeight: "24px",
                        justifyContent: "center",
                        textAlign: "center",
                        
                       
                        
                        }}>
                            <Box style={{
                                display: "flex",
                                padding: "22px 10px",
                                justifyContent: "center",
                                alignItems: "center",
                                width:"40%"
                            }}>
                                שדגשדגשדגשדגSDADSADASDASDבדיקה בדיקה בדיקה בדיקה הבדיקה הבדיקה בדיקה בדיק'ה ביקה שדגחלישדגלחישדגלחישדגלחישדלגחי שדגלחישגדלחישדגלחידשג שדגלחשדגילחשדגי


                            </Box>


                        </Grid>



            </Grid>




                </Grid>

                <Grid container xs={3}  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "32px",
                    height:"600px",
                    marginTop:"20px",
                    
                    

                }}>
                         <Grid item xs={12} style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    lineHeight: "32px",
                    fontWeight: "600",
                    fontFamily:'Assistant',
                    fontSize: "24px",
                    color: "#000",
                    marginBottom: "20px",
                    
                    
                 }}>
                    פוסטים אחרונים בבלוג
                 </Grid>

               <Blogsubpost/>

                <Blogsubpost/>

             
              </Grid>

            </Grid>

        </Grid>


    </Grid>
    )

}

export default DetailedBlog;