import React from "react";
import { Link } from "react-router-dom";
import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthContext from "../Usecontexts/Maincontext";
import Box from '@mui/material/Box';

const Right_Top_Content = ({image,date,title,description}) => {
    return(
    <Grid container xs={12} style={{
        display: "flex",
        flexDirection: "row",
    }}>
    <Grid item xs={6} style={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        alignItems: "flex-start",
    }}>
        <Grid container xs={12} style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
            justifyContent: "flex-start",
        }}>
            <span style={{
                color: "#6941C6",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "600",
                letterSpacing: "0.03em",
            }}>
                {date}
            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
        }}>
            <span style={{
                color: "#1A1A1A",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "18PX",
                lineHeight: "32px",
                fontWeight: "600",
            }}>
                {title}
            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "flex-start",
        }}>
            <span style={{
                color: "#667085",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
            }}>
                {description}
            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
            justifyContent: "flex-start",
            marginTop: "20px",
        }}>
            <Box style={{
                display: "flex",
                padding: "2px 10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                background: "#FDF2FA",
            }}>
                <span style={{
                    color: "#C11574",
                    fontFamily: "Assistant",
                    textAlign: "center",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    fontSize: "14px",
                }}>
                    מחקר
                </span>
            </Box>
            <Box style={{
                display: "flex",
                padding: "2px 10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                background: "#F0F9FF",
            }}>
                <span style={{
                    color: "#026AA2",
                    fontFamily: "Assistant",
                    textAlign: "center",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    fontSize: "14px",
                }}>
                    טכנולוגיות
                </span>
            </Box>
        </Grid>
    </Grid>
    <Grid item xs={6} style={{
        display: "flex",
    }}>
        <img alt="woman_on_comp" src={image} style={{
            width: "280px",
            height: "160px",
            objectFit: "cover",
            boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
        }} />
    </Grid>
    </Grid>
    );
}

export default Right_Top_Content;
