import React from 'react'
import Grid from '@mui/material/Grid'
import open_in_new from '../assets/images/open_in_new.png'
import { useEffect } from 'react'
import Slider from '@mui/material/Slider'
import { useTheme } from '@mui/material/styles'
import { ThemeProvider, createTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useState } from 'react'
import Button from '@mui/material/Button'
import Box from '@mui/material/Box'
import PlaceSpecific from './Placespecific'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'



const Place = ({places,apikey,ischecked,chosenplace, setChosenplace}) => {

    const [position]=useState(0)


 
    if(ischecked){ 

    return (
      
        <>

        <Box 
          sx={{
            display: 'flex',
            alignItems: 'center',
            height: '550px',
            overflowY: 'hidden', // Prevents vertical scrolling
            position: 'relative',
            flexDirection: 'row',
            overflowX: 'Scroll', // Changed here




            
          }}
        >
          <Box
            sx={{
              display: 'flex',

            }}
          >

            {
              places.map((place, index) => (
                <PlaceSpecific name={place.name} rating={place.rating} types={place.types} 
                icon={place.icon} address={place.vicinity} latitude={place.geometry.location.lat} longitude={place.geometry.location.lng}
                 chosenplace={chosenplace} setChosenplace={setChosenplace} placefull={place} ischecked={ischecked}
                />
              ))
            }

    
          </Box>
          <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
           
           
          </Box>
        </Box>
        
        </>
      );
    }
    else{
      return  (
      <>
           <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            height: '550px',
            overflowX: 'auto', // Changed here
            overflowY: 'hidden', // Added to prevent vertical scrolling
            position: 'relative',
          }}
        >
           <Box
             sx={{
               display: 'flex',
               transition: 'transform 0.5s ease-in-out',
               transform: `translateX(${position}px)`,
             }}
           >
 
             {
               places.map((place, index) => (
                 <PlaceSpecific name={place.name} image={place.photos[0].photo_reference} rating={place.rating} types={place.types} 
                 icon={place.icon} address={place.vicinity} apikey={apikey} latitude={place.geometry.location.lat} longitude={place.geometry.location.lng}
                  chosenplace={chosenplace} setChosenplace={setChosenplace} placefull={place} ischecked={ischecked}
                 />
               ))
             }

       
 
     
           </Box>
           <Box sx={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
            
            
           </Box>
         </Box>
      
         </>
      )

    }
    };
    





export default Place