import React from "react";
import Grid from '@mui/material/Grid';
import { useState, useContext, useEffect } from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';
import AuthContext from "../Usecontexts/Maincontext";
import Box from '@mui/material/Box';
import deskimage from '../../assets/images/Blog/deskimage.png';
import woman_comp from '../../assets/images/Blog/woman_computer.jpeg';
import Right_Top_Content from "./Right_Top_Content";
import bottom_left_img from '../../assets/images/Blog/bottom_left_img.png';
import Divider from '@mui/material/Divider';
import Card from "./Card";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";






const Main = () => {
    const { menupress, value, setValue, isvalidationerror, setIsvalidationerror, issucceeded, ishomepage, setIshomepage, isinprojectpage, setIsinprojectpage, setIssucceeded, setMenupress, popup, setPopup, isincontactpage, setIsincontactpage } = useContext(AuthContext);
    const ismobile = useMediaQuery('(max-width:1700px)');
    const navigate = useNavigate();

    useEffect(() => {
        setIshomepage(false);

        return () => {
            setIshomepage(true);
        }

    }, [])

    const gotodeatiledblog = () => {
        navigate("/blog/detailedblog");
    }

    return (
        <>
            <Grid style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                alignSelf: "stretch",
                gap: "50px",
                flexDirection: "column",
                padding: "30px 0px",
                marginTop: "50px",
                backgroundColor: "#FFF",
            }}>
                <Grid container style={{
                    display: "flex",
                    width: "800px",
                    gap: "260px",
                    borderBottom: "0.5px solid rgba(0, 0, 0, 0.24)",
                    borderTop: "0.5px solid rgba(0, 0, 0, 0.24)",
                    alignItems: "center",
                    justifyContent: "center",
                }}>
                    <span style={{
                        color: "#1A1A1A",
                        fontFamily: "Assistant",
                        fontStyle: "normal",
                        fontSize: "60.8px",
                        lineHeight: "normal",
                        fontWeight: "700",
                    }}>
                        <h1 style={{ margin: "0px 0px 20px 0px" }}>הבלוג</h1>
                    </span>
                </Grid>
            </Grid>
            <Grid container style={{
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                gap: "64px",
                alignSelf: "stretch",
                background: "#FFF",
                marginTop: "50px",
            }}>
                <Grid container style={{
                    display: "flex",
                    width: "1200px",
                    padding: "0px 32px",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "32px",
                }}>
                    <Grid item xs={12} style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: "20px",
                        alignItems: "center",
                        justifyContent: "flex-start",
                    }}>
                        <span style={{
                            color: "#1A1A1A",
                            fontFamily: "Assistant",
                            fontStyle: "normal",
                            fontSize: "24px",
                            lineHeight: "32px",
                            fontWeight: "600",
                        }}>
                            פוסטים אחרונים בבלוג
                        </span>
                    </Grid>
                    

                    <Grid container style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "stretch",
                        gap: "32px",
                        maxHeight: "500px",
                    }}>

                    
                      
                        
                        <Grid container xs={6} style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            //gap for the rows
                        }}>
                       <Right_Top_Content image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} />
                      
                       <Right_Top_Content image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} />

                           
                        </Grid>
                        <Grid container xs={6} style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            justifyContent: "flex-end",
                        }}>
                            <Grid container xs={12} style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                                <img alt="deskimage" src={deskimage} style={{
                                    flex: "1 0 0",
                                    alignSelf: "stretch",
                                    
                                    objectFit: "cover",
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                }} />
                            
                            </Grid>

                            <Grid container style={{
                                flexDirection: "row",
                                display: "flex",
                                gap: "10px",
                                alignItems: "flex-start",
                            }}>
                                <Grid item xs={12} style={{
                                    flexDirection: "row",
                                    display: "flex",
                                }}>

                               
                               <span style={{
                                        color: "#6941C6",
                                        fontFamily: "Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "600",
                                        letterSpacing: "0.03em",
                                    }}>
                                        ראשון, 4 לאוגוסט 2024
                                    </span> 

                                    </Grid> 
                                    <Grid container xs={12} style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}>
                                        <Grid item xs={10}>
                                            <span style={{
                                                color: "#1A1A1A",
                                                fontFamily: "Assistant",
                                                fontStyle: "normal",
                                                fontSize: "18PX",
                                                lineHeight: "32px",
                                                fontWeight: "600",
                                            }}>
                                                אילו תובנות ניתן להפיק באמצעות AI?
                                            </span>
                                        </Grid>
                                        <Grid item xs={2} style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            alignItems: "center",
                                            cursor: "pointer",
                                        }} onClick={()=> gotodeatiledblog()} >
                                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
<path d="M7 17L17 7M17 7H7M17 7V17" stroke="#1A1A1A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
</svg>
                                        </Grid>
                                    </Grid>
  
                                                                 </Grid>

                                </Grid>
                        </Grid>
             
                    </Grid>
                    <Grid container style={{
                    display: "flex",
                    width: "1440px",
                    padding: "30px 0px",
                    flexDirection: "row",
                    alignItems: "flex-start",
                    gap: "32px",
                    maxHeight:"400px"
                }}>
                     <Grid container style={{
                        display: "flex",
                        flexDirection: "column",
                        alignSelf: "stretch",
                        gap: "32px",

                    }}>

                    
                        <Grid container xs={6} style={{
                            display: "flex",
                            flexDirection: "row",
                            //gap for the rows
                        }}>
       
      
        <Grid container xs={12} style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "center",
            justifyContent: "flex-start",
            
            
        }}>
            <span style={{
                color: "#6941C6",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "14px",
                lineHeight: "24px",
                fontWeight: "600",
                letterSpacing: "0.03em",
            }}>
                      ראשון, 4 לאוגוסט 2024

            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop:"-10px"
        }}>
            <span style={{
                color: "#1A1A1A",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "18PX",
                lineHeight: "32px",
                fontWeight: "600",
            }}>
                        אילו תובנות ניתן להפיק באמצעות AI?

            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "flex-start",
        }}>
            <span style={{
                color: "#667085",
                fontFamily: "Assistant",
                fontStyle: "normal",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "400",
            }}>
                באמצעות AI ניתן לבצע המון דברים כגון
            </span>
        </Grid>
        <Grid item xs={12} style={{
            display: "flex",
            flexDirection: "row",
            gap: "20px",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "20px",
        }}>
            <Box style={{
                display: "flex",
                padding: "2px 10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                background: "#FDF2FA",
            }}>
                <span style={{
                    color: "#C11574",
                    fontFamily: "Assistant",
                    textAlign: "center",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    fontSize: "14px",
                }}>
                    מחקר
                </span>
            </Box>
            <Box style={{
                display: "flex",
                padding: "2px 10px",
                justifyContent: "center",
                alignItems: "center",
                borderRadius: "16px",
                background: "#F0F9FF",
            }}>
                <span style={{
                    color: "#026AA2",
                    fontFamily: "Assistant",
                    textAlign: "center",
                    fontStyle: "normal",
                    fontWeight: "500",
                    lineHeight: "20px",
                    fontSize: "14px",
                }}>
                    טכנולוגיות
                </span>
            </Box>
        </Grid>
                             
                            
                        </Grid>
                        <Grid container xs={6} style={{
                            display: "flex",
                            flexDirection: "row",
                            gap: "20px",
                            //gap for the rows
                        }}>
        <Grid container xs={12} style={{
                                display: "flex",
                                flexDirection: "row",
                            }}>
                                <img alt="bottom_left_img" src={bottom_left_img} style={{
                                    flex: "1 0 0",
                                    alignSelf: "stretch",
                                    height: "270px",
                                    
                                    
                                    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                                }} />
                            
                            </Grid>
                        </Grid>
                        </Grid>

                        </Grid>
                        <Grid container style={{
                    display: "flex",
                    width: "1440px",
                    padding: "30px 0px",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "32px",
                }}>
                     <Grid container style={{
                        display: "flex",
                        flexDirection: "row",
                        alignSelf: "stretch",
                        gap: "32px",
                        width:"1280px"
                    }}>

                    
                        <Grid container xs={12} style={{
                            display: "flex",
                            flexDirection: "row",
                           
                            //gap for the rows
                        }}>

                            <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />
                            <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />
                            <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />

                         
                           
                            </Grid>
                            <Grid container xs={12} style={{
                            display: "flex",
                            flexDirection: "row",
                           
                            //gap for the rows
                        }}>
                             <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />
                          <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />
                            <Card image={woman_comp} date={"דור רצבי • 5 לאוגוסט 2024"} title={"אילו תובנות ניתן להפיק באמצעות AI?"} description={"האם בעתיד נוכל להפיק תובנות מהמידע הגדול באמצעות AI? ואם כן, אילו תובנות ניתן להפיק?"} topic1={"טכנולוגיות"} topic2={"מחקר"} topic3={"פיתוח"} />

                        
                          
                          
                            </Grid>

                            </Grid>

                           

                            </Grid>

                            <Divider component={"div"} style={{
                                width: "1280px",
                                height: "1px",
                                marginTop: "30px",
                                color: "#667085"
                            }} />
                            <Grid container style={{
                                display: "flex",
                                flexDirection:'column',
                                alignItems: "center",
                                justifyContent: "center",
                                gap: "64px",
                                alignSelf: "stretch",
                                padding: "30px 0px",
                            }}>
                                <Grid container style={{
                                    display: "flex",
                                    width: "1200px",
                                    alignItems:'flex-start',
                                    gap: "14px",
                                }}>

                                    <span style={{
                                        color: "#1A1A1A",
                                        fontfamily:"Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        
                                    }}>
                                        לינקדאין
                                    </span>
                                    <span style={{
                                        color: "#1A1A1A",
                                        fontfamily:"Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        
                                    }}>
                                        פייסבוק
                                    </span>
                                    <span style={{
                                        color: "#1A1A1A",
                                        fontfamily:"Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        
                                    }}>
                                        אינסטגרם
                                    </span>
                                    <span style={{
                                        color: "#1A1A1A",
                                        fontfamily:"Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        
                                    }}>
                                        מייל
                                    </span>
                                    <span style={{
                                        color: "#1A1A1A",
                                        fontfamily:"Assistant",
                                        fontStyle: "normal",
                                        fontSize: "14px",
                                        lineHeight: "24px",
                                        fontWeight: "400",
                                        
                                    }}>
                                          2024 © 
                                    </span>

                                    </Grid>

                           

                            </Grid>
                                
                </Grid>
        </>
    );
}

export default Main;
